// Template for [JS3Doc](https://jsdoc.app/) documentation auto-generation
// Add / remove sections below as needed
/**
 * DataManagementApi.ts
 *
 * Client methods implementing the quanterix-protos/data_management/v1 API
 */
import { createChannel, createClient } from 'nice-grpc-web';
import * as DataManagement from 'quanterix-protos/data_management/v1/data_management';
import {
  GetAllAssaysRequest,
  GetAllExperimentsRequest,
  GetAnalysisResultsRequest,
  GetAssayRequest,
  GetAssaysByExperimentRequest,
  GetPlateRequest,
  GetExperimentRequest,
  GetVersionsRequest,
  ListAnalysisResultsRequest,
  WriteExperimentRequest,
  WriteAssayRequest,
  GetCurveResultsRequest,
  RunAnalysisRequest,
  WritePlateRequest,
  GetInstrumentDataJobStatusResponse,
  ValidateExperimentSetupResponse,
  ValidateExperimentSetupRequest,
  GetInstrumentDataJobStatusRequest,
} from 'quanterix-protos/data_management/v1/data_management_messages';
import { AnalysisResultsDto } from 'quanterix-protos/run_data/v1/db_analysis_results';
import { AssayProtocolDto } from 'quanterix-protos/run_data/v1/db_assay';
import {
  PlateBody,
  PlateDto,
  WellDto,
} from 'quanterix-protos/run_data/v1/db_plate';
import { RunPackageBody } from 'quanterix-protos/run_data/v1/db_run_package';
import { PlateWellType } from 'quanterix-protos/type/plate_well_types';
import {
  DataManagementThunkApi,
  ExperimentData,
  AssayData,
} from 'quil';

const dataManagementAddress = `/data-manager`;

const dataManagementChannel = createChannel(dataManagementAddress);

export const dataMgr: DataManagement.DataManagementClient = createClient(
  DataManagement.DataManagementDefinition,
  dataManagementChannel,
);

export async function GetVersions() {
  const request = GetVersionsRequest.create({});
  const response = await dataMgr.getVersions(request);
  return response;
}

// TODO: This can be made available as an export from quil
// Or export an empty plate
const flatWellAddressesByCol: string[] = [];
for (let col = 0; col < 12; col += 1) {
  for (let row = 0; row < 8; row += 1) {
    const rowChar = 65 + row;
    const colStr = (col + 1).toString().padStart(2, '0');
    const address = `${String.fromCharCode(rowChar)}${colStr}`;
    flatWellAddressesByCol.push(address);
  }
}

// TODO: Incomplete - error traced to empty Controls set in editor (fix in quil)
/**
 * Create a new empty experiment
 * @param experimentName Name of the new experiment
 * @returns RunPackageDto
 */
export async function createNewExperiment(experimentName: string){
  const emptyPlate = flatWellAddressesByCol.map((address) => WellDto.create({wellAddress: address, wellType: PlateWellType.PLATE_WELL_TYPE_EMPTY}));
  const plateBody: PlateBody = {
    plateName: `${experimentName}_plate`,
    inDraft: false,
    wells: [...emptyPlate],
  }
  const writePlateRequest: WritePlateRequest = {
    body: plateBody,
  }

  const plateResponse = await dataMgr.writePlate(writePlateRequest);

  const experimentBody = RunPackageBody.create({
    runPackageName: experimentName,
    locked: false,
    plateId: plateResponse.plate?.plateId,
    analysisConfiguration: {}
  });

  const writeExperimentRequest: WriteExperimentRequest = {
    body: experimentBody
  }

  const writeExperimentResponse = await dataMgr.writeExperiment(writeExperimentRequest);

  return writeExperimentResponse?.runPackage
}


export const DataManagementApi: DataManagementThunkApi = {
  async getAssayData(assayId: string) {
    // could just use getAssayProtocol and getAssayDefinition, but that requires twice as many API requests
    const assayDetailsRequest = GetAssayRequest.create({
      assayProtocolId: assayId,
    });
    const assayDetailsResponse = await dataMgr.getAssay(assayDetailsRequest);
    // controls does not exist in the protos yet, so I'm using this placeholder
    if (!assayDetailsResponse.protocol ||
      !assayDetailsResponse.assay ||
      !assayDetailsResponse.assay.data) {
      throw new Error(
        `Response ${assayDetailsResponse} is missing information`
      );
    }

    const data: AssayData = {
      assayDefinition: assayDetailsResponse.assay,
      assayProtocol: assayDetailsResponse.protocol,
    };
    return data;
  },
  async getAssayProtocol(assayProtocolId: string) {
    const assayProtocolRequest = GetAssayRequest.create({ assayProtocolId });
    const assayProtocolResponse = await dataMgr.getAssay(assayProtocolRequest);
    if (!assayProtocolResponse.protocol) {
      throw new Error(
        `Response ${assayProtocolResponse} is missing information`
      );
    }
    return assayProtocolResponse.protocol;
  },
  async getAssayList() {
    const assayListRequest = GetAllAssaysRequest.create({});
    const assayListResponse = await dataMgr.getAllAssays(assayListRequest);

    const allAssays: AssayProtocolDto[] = await Promise.all(
      assayListResponse.assayProtocols.map((protocol) => this.getAssayProtocol(protocol.id)
      )
    );
    return allAssays || [];
  },
  async getAssayDefinition(assayDefinitionId: string) {
    const assayDefinitionRequest = GetAssayRequest.create({
      assayProtocolId: assayDefinitionId,
    });

    const assayDefinitionResponse = await dataMgr.getAssay(
      assayDefinitionRequest
    );
    if (!assayDefinitionResponse.assay || !assayDefinitionResponse.assay.data) {
      throw new Error(
        `Response ${assayDefinitionResponse} is missing information`
      );
    }
    const assayDefinition = assayDefinitionResponse?.assay;
    return assayDefinition;
  },
  async getExperimentList() {
    const experimentsRequest = GetAllExperimentsRequest.create({});
    const experimentsResponse = await dataMgr.getAllExperiments(experimentsRequest);
    const allExperiments = await Promise.all(
      experimentsResponse.experiments.map((experiment) => dataMgr.getExperiment({ runId: experiment.id })
      )
    );
    return allExperiments.map((e) => e.experiment!);
  },
  async getExperimentData(experimentId: string) {
    const runRequest = GetExperimentRequest.create({ runId: experimentId });
    const runResponse = await dataMgr.getExperiment(runRequest);
    if (!runResponse.experiment || !runResponse.experiment.data) {
      throw new Error(`Response ${runResponse} is missing information`);
    }

    let plate = PlateDto.fromPartial({});
    if (runResponse.experiment.data.plateId) {
      const plateRequest = GetPlateRequest.create({
        plateId: runResponse.experiment.data.plateId,
      });
      const plateResponse = await dataMgr.getPlate(plateRequest);
      if (!plateResponse.plate) {
        // TODO: handle error
        // throw new Error(`Response ${plateResponse} is missing information`);
      }
      plate = plateResponse.plate as PlateDto;
    } else {
      // If no plate id is assigned, we should create a blank one now
      const emptyWells = flatWellAddressesByCol.map((address) => WellDto.fromJSON({
        wellAddress: address,
        wellType: PlateWellType.PLATE_WELL_TYPE_EMPTY,
        wellName: address,
      })
      );

      const plateBody = PlateBody.fromPartial({
        plateName: `${runResponse.experiment.data.runPackageName}-plate`,
        // createdAccountId: activeAccount?.username.toString() || '',
        // modifiedAccountId: activeAccount?.username.toString() || '',
        // organizationId: activeAccount?.tenantId.toString() || '',
        inDraft: true,
        wells: [...emptyWells],
      });

      const writePlateRequest = WritePlateRequest.create({
        body: plateBody,
      });

      const newPlateResponse = await dataMgr.writePlate(writePlateRequest);
      plate = newPlateResponse.plate as PlateDto;
    }

    const analysisResultsListRequest = ListAnalysisResultsRequest.create({
      runId: experimentId,
    });
    const analysisResultsListResponse = await dataMgr.listAnalysisResults(
      analysisResultsListRequest
    );
    const analysisResultsRequest = GetAnalysisResultsRequest.create({
      analysisResultsId: analysisResultsListResponse?.analysisResults[0]?.id,
    });

    const analysisResultsResponse = await dataMgr.getAnalysisResults(
      analysisResultsRequest
    );

    const analysisResultsToUse = analysisResultsResponse.results;
    const assaysRequest = GetAssaysByExperimentRequest.create({
      runId: experimentId,
    });

    const assaysResult = await dataMgr.getAssaysByExperiment(assaysRequest);
    const assayDetails = await Promise.all(
      assaysResult?.assayProtocols?.map((id) => this.getAssayData(id.id))
    );

    if (!analysisResultsToUse) {
      // TODO: handle error
    }

    const caster = analysisResultsToUse as AnalysisResultsDto;

    const allData: ExperimentData = {
      experiment: runResponse.experiment,
      assayProtocols: assayDetails?.map((a) => a.assayProtocol) || [],
      assayDefinitions: assayDetails?.map((a) => a.assayDefinition) || [],
      plate,
      analysisResults: caster,
    };

    return allData;
  },
  async saveExperimentDraft() {
    throw new Error('not implemented');
  },
  async saveAssayDraft() {
    throw new Error('not implemented');
  },
  // Requires an updated in DataManagement to leverage
  // IMessage.MergeFrom
  async saveExperiment(experimentData) {
    if (!experimentData.experiment) {
      throw new Error(`Request ${experimentData} is missing information`);
    }

    const updatedData = RunPackageBody.fromPartial({
      ...experimentData.experiment?.data,
    });

    // Initialize an empty plate if one does not exist yet in the experiment
    if (!experimentData.plate) {
      // TODO: this shouldn't be a valid scenario here
      // Review condition and update if necessary
    } else {
      const writePlateRequest: WritePlateRequest = {
        plateId: experimentData.plate.plateId,
        body: experimentData.plate.data,
      };
      await dataMgr.writePlate(writePlateRequest);
    }

    const writeExperimentRequest = WriteExperimentRequest.create({
      runId: experimentData.experiment.runPackageId || undefined,
      body: updatedData,
    });

    await dataMgr.writeExperiment(writeExperimentRequest);
  },
  async saveAssay(assayData) {
    if (!assayData.assayDefinition || !assayData.assayProtocol) {
      throw new Error(`Request ${assayData} is missing information`);
    }
    const writeAssayRequest = WriteAssayRequest.create({
      bodyProtocol: assayData.assayProtocol.data,
      bodyDefinition: assayData.assayDefinition.data,
    });
    await dataMgr.writeAssay(writeAssayRequest);
  },
  async saveExperimentAs(experimentData) {
    const writeExperimentRequest = WriteExperimentRequest.create({
      body: experimentData.experiment.data,
      runId: experimentData.experiment.runPackageId,
    });
    const writeExperimentResponse = await dataMgr.writeExperiment(
      writeExperimentRequest
    );
    if (!writeExperimentResponse.runPackage) {
      throw new Error(
        `Response ${writeExperimentResponse} is missing information`
      );
    }
    return this.getExperimentData(
      writeExperimentResponse.runPackage.runPackageId
    );
  },
  async saveAssayAs(assayData) {
    const writeAssayRequest = WriteAssayRequest.create({
      bodyProtocol: assayData.assayProtocol.data,
      bodyDefinition: assayData.assayDefinition.data,
      assayProtocolId: assayData.assayDefinition.assayDefinitionId,
    });
    const writeAssayResponse = await dataMgr.writeAssay(writeAssayRequest);

    if (!writeAssayResponse.assay ||
      !writeAssayResponse.assay.data ||
      !writeAssayResponse.protocol) {
      throw new Error(`Response ${writeAssayResponse} is missing information`);
    }


    return { assayProtocol: writeAssayResponse.protocol, assayDefinition: writeAssayResponse.assay };
  },
  async getAnalysisResultsData(experimentId) {
    // For now, always run analysis
    await dataMgr.runAnalysis(
      RunAnalysisRequest.create({ runId: experimentId })
    );

    const getCurveResultsRequest = GetCurveResultsRequest.create({
      runId: experimentId,
    });

    const getCurveResultsResponse = await dataMgr.getCurveResults(
      getCurveResultsRequest
    );

    return {
      results: getCurveResultsResponse,
    };
  },
  validateExperimentSetup (experimentId: string): Promise<ValidateExperimentSetupResponse> {
    const request = ValidateExperimentSetupRequest.create({experimentId});
    return dataMgr.validateExperimentSetup(request);
  },
  getInstrumentDataStatus (experimentId: string): Promise<GetInstrumentDataJobStatusResponse> {
    const request = GetInstrumentDataJobStatusRequest.create({experimentId});
    return dataMgr.getInstrumentDataJobStatus(request);
  }
};
